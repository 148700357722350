<template>
  <v-menu
    :disabled="disabled"
    v-model="showDatePicker"
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        ref="auto"
        :label="label"
        v-bind:value="value"
        v-on:input="$emit('input', $event)"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
        clearable
        @click:clear="$emit('clear')"
        :error-messages="errorMessages"
        :rules="onlyRequired"
      ></v-text-field>
    </template>
    <v-date-picker
      v-bind:value="value"
      v-on:input="$emit('input', $event)"
      @input="showDatePicker = false"
      header-color="primary"
      :type="type"
      :disabled="disabled"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import rules from "@/utils/form.rules";

export default {
  props: ["label", "value", "error-messages", "type", "reset", "disabled"],
  data: () => ({
    showDatePicker: false,
    onlyRequired: [rules.required],
  }),
  watch: {
    reset: function () {
      this.$refs.auto.reset();
    },
  },
};
</script>
